<template>
  <v-overlay :value="isLoading">
    <v-progress-circular
      indeterminate
      size="64"
    />
  </v-overlay>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'GlobalLoader',
    computed: mapGetters({
      isLoading: 'isLoading',
    }),
  }
</script>

<style scoped>

</style>

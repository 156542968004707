<template>
  <v-app>
    <global-loader />
    <v-main>
      <v-container
        id="user-profile"
        fluid
        tag="section"
        class="pt-15"
      >
        <v-row justify="center">
          <v-col
            cols="12"
            xl="4"
          >
            <base-material-card
              class="v-card-profile"
              :avatar="$store.state.logo"
            >
              <v-card-text class="text-center">
                <h4 class="text-h3 font-weight-light mb-3 black--text">
                  {{ $t('app-name') }}
                </h4>
                <h6 class="text-h4 mb-1 grey--text">
                  {{ $t('login-instruction') }}
                </h6>

                <v-form>
                  <v-container class="py-10">
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="username"
                          class="purple-input"
                          :label="$t('username')"
                          @focus="showErrorMessage = false"
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="password"
                          :label="$t('password')"
                          class="purple-input"
                          type="password"
                          @focus="showErrorMessage = false"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>

                <base-material-alert
                  v-show="showErrorMessage"
                  class="mb-15 mt-n3"
                  color="warning"
                  dark
                  icon="mdi-alarm-light"
                >
                  {{ $t('login-error-message') }}
                </base-material-alert>

                <v-btn
                  color="default"
                  rounded
                  class="mr-0"
                  @click="login"
                >
                  {{ $t('login') }}
                </v-btn>
              </v-card-text>
            </base-material-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import GlobalLoader from '../../components/GlobalLoader'

  export default {
    name: 'Login',
    components: { GlobalLoader },
    data () {
      return {
        username: '',
        password: '',
        showErrorMessage: false,
      }
    },
    methods: {
      async login () {
        this.showErrorMessage = false
        const loginResult = await this.$store.dispatch(
          'auth/login',
          {
            username: this.username,
            password: this.password,
          },
        )

        if (loginResult) {
          await this.$router.push('/couriers/workloads')
          return
        }

        this.showErrorMessage = true
        this.username = ''
        this.password = ''
      },
    },
  }
</script>

<style scoped>

</style>
